@import url("dubai-fonts.css");
/* @import url("bootstrap/dist/css/bootstrap.min.css");
@import url("react-alice-carousel/lib/alice-carousel.css"); */
@import url("https://pcfc.ae/_catalogs/masterpage/en-us/2/assets/css/style.css");
body {
  font-size: 16px;
  font-family: "Dubai-Regular";
}
.rtl-dir {
  direction: rtl;
}
.material-symbols-outlined {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
}
.disclaimer {
  color: #b3b3b3 !important;
  text-decoration: none !important;
}
.disclaimer:hover {
  color: #2b6182 !important;
}
/* shore menu */
.l-h26 {
  line-height: 26px;
}
.c-blue {
  color: #33aad3 !important;
}
.bold {
  font-family: "Dubai-Bold";
}
.regular {
  font-family: "Dubai-Regular" !important;
}
.fs34 {
  font-size: 34px !important;
}
.mt-085 {
  margin-top: 0.85rem;
}
.shore-menu {
  background-color: #2b6182;
}
.shore-menu ul {
}
.menu-bg {
  background-color: #2b6182;
}
.shore-menu ul li a {
  display: block;
  color: #fff;
  padding: 10px;
  text-transform: uppercase;
  text-decoration: none !important;
}
.shore-menu ul li a:hover {
  background-color: #b5892e;
}
.shore-menu ul li a.active {
  background-color: #b5892e;
}

.header-bg {
  background-image: url("../images/weather-bg.png");
  background-size: cover;
  background-repeat: no-repeat, no-repeat;
  background-position: center top;
  min-height: 510px;
  position: relative;
}

.header-content {
  position: relative;
  height: 510px;
}

.tcd {
  position: relative;
  top: 50px;
  left: 0px;
  color: white;
}

.tcd .time {
  font-size: 36px;
}
.tcd span {
  font-size: 16px;
}
.tcd .country {
  font-size: 16px;
}
.tcd .date {
  font-size: 16px;
}
.currentweather {
  position: relative;
  top: 60px;
}
.inner-currentweather {
  color: black;
  background-color: white;
  border-radius: 10px;
  padding: 15px 25px 10px;
}
.currentweather .firstdiv {
  font-size: 16px;
  width: 90px;
}
.currentweather .firstdiv span {
  font-size: 22px;
}
.currentweather .secdiv ul li {
  border-bottom: solid 1px #e9e9e9;
  padding: 5px 0;
  display: flex;
  width: 100% !important;
}
.currentweather .secdiv ul li > span {
  text-align: right;
  margin-right: 10px;
  display: flex;
  justify-content: end;
  width: 60px;
  font-weight: 600;
}

.header-activity {
  position: relative;
  top: 60px;
  left: 10px;
  color: white;
  background-color: rgba(0, 0, 0, 0.161);
  border-radius: 10px;
  padding: 15px 25px 10px;
}
.header-activity .heading {
  font-size: 22px;
  line-height: 26px;
}
.header-activity div.wactivity {
  background-color: #fff;
  padding: 5px 5px 0px 5px;
  border-radius: 10px;
  margin: 0 0 10px;
  color: #000;
  display: flex;
}
.header-activity div.wactivity div span {
  font-size: 12px;
  display: block;
}
.header-activity div.wactivity > span.green {
  border-radius: 25px;
  background-color: #30d158;
  padding: 5px;
  color: #fff;
  margin: 2px 10px 0 0;
  height: 35px;
}
.header-activity div.wactivity > span.blue {
  border-radius: 25px;
  background-color: #0a84ff;
  padding: 5px;
  color: #fff;
  margin: 2px 10px 0 0;
  height: 35px;
}
.weather-tab {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.fc-heading {
  font-size: 22px;
  color: black !important;
  line-height: 26px;
}
.weather-header-container {
  overflow-x: scroll;
  height: 100%;
  white-space: nowrap;
  /* scroll-snap-type: x mandatory; */
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.weather-header-container::-webkit-scrollbar {
  display: none;
}
.weather-content-tab {
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.weather-content-tab li {
  width: 100%;
  margin: 0 5px;
}
.weather-content-tab li:first-child {
  margin-left: 0 !important;
}
.weather-content-tab li:last-child {
  margin-right: 0 !important;
}
.weather-content-tab-ar li:first-child {
  margin-right: 0 !important;
}
.weather-content-tab-ar li:last-child {
  margin-left: 0 !important;
}
.weather-header-tab {
  /* overflow-x: auto;
  overflow-y: hidden; */
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.weather-header-tab li {
  color: #000;
  background-color: #f6f6f6;
  cursor: pointer;
  /* scroll-snap-align: start; */
  width: 100%;
  padding: 22px 0;
}
.weather-header-tab li.active {
  background-color: white;
}
.weather-header-tab li:first-child {
  border-radius: 0.375rem 0 0 0px;
  margin-left: 0;
}
.weather-header-tab li:last-child {
  border-radius: 0 0.375rem 0px 0;
  margin-right: 0;
}
.weather-header-tab-ar li:first-child {
  border-radius: 0 0.375rem 0 0px;
  margin-left: 0;
}
.weather-header-tab-ar li:last-child {
  border-radius: 0.375rem 0px 0px 0;
  margin-right: 0;
}
.weather-header-tab li span.material-symbols-outlined {
  color: #ff9f0a;
  font-size: 50px;
}
.weather-header-tab li.active span.material-symbols-outlined {
  color: #ff9f0a;
  /* margin: 10px 0; */
}
.weather-header-tab::-webkit-scrollbar {
  display: none;
}
.carousel-item-padding-40-px {
  /* padding: 0 20px !important; */
  /* width: 270px !important; */
  /* height: 200px !important; */
}
.carousel-padding {
  /* padding: 24px !important; */
}
.custom-dot-list-style {
  margin-top: 24px !important;
}
.custom-dot-list-style li {
  margin: 0px 8px !important;
}
.custom-dot-list-style li button {
  border: none !important;
  background-color: #d6d6d6;
  width: 9px !important;
  height: 9px !important;
}
.custom-dot-list-style .react-multi-carousel-dot--active button {
  background-color: #869791;
}
/* .my-own-custom-container {
  padding: 50px;
} */
/* .carousel-item-padding-40-px {
  padding-right: 40px !important;
} */
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: white;
  color: black;
}
.nav-pills li:first-child .nav-link {
  padding: 22px 0;
  border-radius: 10px 0 0 0px;
}
.nav-pills li:first-child .nav-link.active {
  border-radius: 10px 0 0 0px;
}
.nav-pills li .nav-link.active span.material-symbols-outlined {
  color: #ff9f0a;
  margin: 10px 0;
}
.nav-pills li:last-child .nav-link.active,
.nav-pills li:last-child .nav-link {
  border-radius: 0 10px 0px 0;
}
.nav-pills .nav-link {
  color: #000;
  background-color: #f6f6f6;
}
ul.nav li.nav-item {
  width: 185px;
}
ul.mobile li.nav-item:first-child,
ul.mobile li.nav-item:last-child {
  width: auto;
}
ul.nav li.nav-item a {
  padding: 0 10px !important;
  height: 100%;
  display: flex;
  align-items: center;
}
ul.nav li.nav-item button {
  width: 100%;
  border-radius: 0px;
  padding: 22px 0;
}
ul.nav li.nav-item button span.material-symbols-outlined {
  color: #ff9f0a;
  margin: 10px 0;
  font-size: 50px;
}
.nav-pills li .nav-link span.fd-bold {
  text-transform: uppercase;
}
.tab-content .tab-pane div div.weakly-hours {
  color: #000;
  background-color: #f6f6f6;
  border-radius: 10px;
  padding: 15px 25px 10px;
  border: solid 1px #e9e9e9;
}
div div.weakly-hours .fd-bold {
  font-weight: 600;
}
div div.weakly-hours .color {
  color: #33aad3;
  font-size: 34px;
  font-weight: 600;
}
div div.weakly-hours .color span {
  font-size: 16px;
  font-weight: 400;
}
.weather-condition {
  display: flex;
  justify-content: center;
}
.weather-condition ul {
  padding: 0;
  margin-top: 10px;
  border-top: solid 1px #e9e9e9;
}
.weather-condition ul li {
  border-bottom: solid 1px #e3e3e3;
  padding: 5px 10px;
  display: flex;
}
.weather-condition ul li > span {
  text-align: right;
  margin-right: 10px;
  display: flex;
  width: 60px;
  font-weight: 600;
  justify-content: end;
}
.weather-activity div.wactivity {
  background-color: #fff;
  padding: 5px 5px 0px 5px;
  border-radius: 10px;
  margin: 0 auto 10px;
  color: #000;
  display: flex;
  border: solid 1px #eaeaea;
  width: 150px;
}
.weather-activity div.wactivity div span {
  font-size: 12px;
  display: block;
}
.weather-activity div.wactivity > span.green {
  border-radius: 25px;
  background-color: #30d158;
  padding: 5px;
  color: #fff;
  margin: 2px 10px 0 0;
  height: 35px;
}
.weather-activity div.wactivity > span.blue {
  border-radius: 25px;
  background-color: #0a84ff;
  padding: 5px;
  color: #fff;
  margin: 2px 10px 0 0;
  height: 35px;
}

.copyright {
  color: #b3b3b3;
  font-size: 14px;
  line-height: 20px;
}

.the-weather-report-i {
  color: #b3b3b3;
  font-size: 14px;
  text-align: right;
}
.nav-pills {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  justify-content: center;
}
/* .weather-header-tab li {
  width: 100%;
} */
.control-button-prev,
.control-button-next {
  display: none;
}
.control-button-prev {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.control-button-next {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.current-weather-title {
  width: 100%;
  white-space: inherit !important;
}
/* @media screen and (max-width: 600px) { */
@media screen and (max-width: 1366px) {
  .header-bg {
    min-height: 600px !important;
  }
}
@media screen and (max-width: 1024px) {
  .header-bg {
    min-height: 510px !important;
  }
  .currentweather {
    top: 75px;
  }
  .copyright,
  .the-weather-report-i {
    text-align: center;
  }

  .weather-tab {
    position: unset;
    display: none !important;
  }

  .control-button-prev,
  .control-button-next {
    display: block;
    /* position: absolute; */
    /* top: 0; */
    /* bottom: 0; */
    width: 25px;
    height: 100%;
    border: none;
    color: #e1e1e1;
  }

  .weather-header-tab li {
    /* width: 185px; */
    flex-shrink: 0; /* Prevent list items from shrinking */
    /* width: calc(100% / 3); */
    width: 125px;
    height: 140px !important;
  }

  .weather-header-tab li {
    margin: 0 5px;
    border: #dee2e6 solid 1px;
    border-radius: 0.375rem;
  }

  .weather-header-tab li:first-child,
  .weather-header-tab li:last-child {
    /* border: #5c6166 solid 2px; */
    border-radius: 0.375rem;
  }
  /* .weather-header-tab li:last-child {
    width: 100% !important;
  } */
  /* .weather-header-tab li:first-child {
    padding-left: 185px;
  } */
  .currentweather .secdiv {
    width: 100%;
  }
  .currentweather .secdiv ul {
    width: 100%;
  }
  .currentweather .secdiv ul li {
    text-align: center;
  }
  .currentweather .secdiv ul li > span {
    text-align: center;
  }
  .current-weather-title {
    width: 100%;
    white-space: nowrap !important;
  }
}

.fa-w-14 {
  color: #e1e1e1;
}

/* .owl-theme .owl-dots .owl-dot span {
  margin: 5px 5px;
} */
.space {
  margin: 0 12px;
}

.space:last-child,
.space:first-child {
  margin: 0;
}

/* .alice-carousel__stage-item:has(> .item) {
  margin: 0 5px !important;
} */

.react-multi-carousel-dot-list {
  position: unset !important;
}

.react-multi-carousel-track .weather-hour-step-item {
  padding: 0px 5px;
}

/* select.language-select option[value="nearshore"] {
  background-color: #b5892e;
} */

select.language-select option:hover {
  background-color: #b5892e;
}

/* .carousel-item-padding-40-px {
  padding: 0 40px !important;
} */
/* .react-multi-carousel-track .weather-hour-step-item:first-child {
  padding-left: 0 !important;
}

.react-multi-carousel-track .weather-hour-step-item:last-child {
  padding-right: 0 !important;
} */

/* .dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.dot-active {
  background-color: #5c6166;
} */
