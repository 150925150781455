@font-face {
  font-family: "Dubai-Bold";
  src: url("../dubaifonts/Dubai-Bold.eot");
  src: url("../dubaifonts/Dubai-Bold.eot") format("embedded-opentype"),
    url("../dubaifonts/Dubai-Bold.woff2") format("woff2"),
    url("../dubaifonts/Dubai-Bold.woff") format("woff"),
    url("../dubaifonts/Dubai-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Dubai-Medium";
  src: url("../dubaifonts/Dubai-Medium.eot");
  src: url("../dubaifonts/Dubai-Medium.eot") format("embedded-opentype"),
    url("../dubaifonts/Dubai-Medium.woff2") format("woff2"),
    url("../dubaifonts/Dubai-Medium.woff") format("woff"),
    url("../dubaifonts/Dubai-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Dubai-Regular";
  src: url("../dubaifonts/Dubai-Regular.eot");
  src: url("../dubaifonts/Dubai-Regular.eot") format("embedded-opentype"),
    url("../dubaifonts/Dubai-Regular.woff2") format("woff2"),
    url("../dubaifonts/Dubai-Regular.woff") format("woff"),
    url("../dubaifonts/Dubai-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Dubai-Light";
  /* src: url("../dubaifonts/Dubai-Light"); */
  src: url("../dubaifonts/Dubai-Light.eot") format("embedded-opentype"),
    url("../dubaifonts/Dubai-Light.woff2") format("woff2"),
    url("../dubaifonts/Dubai-Light.woff") format("woff"),
    url("../dubaifonts/Dubai-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.fd-bold {
  font-family: "Dubai-Bold", Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}

.fd-medium {
  font-family: "Dubai-Medium", Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}

.fd-regular {
  font-family: "Dubai-Regular", Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}

.fd-light {
  font-family: "Dubai-Light", Helvetica, sans-serif !important;
  font-weight: normal;
  font-style: normal;
}
